import SimpleSchema from 'simpl-schema';

import store from '../../config/configureStore';

import {
  crimeAgainstPersonCodes,
  crimeAgainstPropertyCodes,
  crimeAgainstSocietyCodes,
} from '../helpers/Codes/UCR';

import { getCategoryOfCode } from 'utils/functions';

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-141':
        'A Person Injury Category can only be provided when the associated Offense code is (100,11A,11B,11C,11D,120,13A,13B,210,64A,64B).',
      'NIBRS-2019.2-143':
        'When the Victim Category is not I or L, then Injury Category must be omitted.',
      'NIBRS-2019.2-146':
        'When the associated Offense code is 13B, then the Injury Category must be M or N.',
      'NIBRS-2019.2-115': 'Victim Category is required when Victim is provided.',
      'NIBRS-2019.2-116':
        'Victim Category must be (I, L) when the Offense Code is a Crime Against Person.',
      'NIBRS-2019.2-117':
        'Victim Category must be "S" when the Offense Code is a Crime Against Society.',
      'NIBRS-2019.2-118':
        'Victim Category can not be "S" when the Offense Code is a Crime Against Property.',
      'NIBRS-2019.2-119':
        'Victim Category can not be "L" unless the Offense Code is (09A, 13A, 13B, or 13C).',
      'NIBRS-2019.2-133':
        'The Victim Aggravated Assault Homicide Factor can only be provided when the associated Offense is (09A,09B,09C,13A).',
      'NIBRS-2019.2-121(Enforcement Official Activity Category)':
        'When the Victim Category is L then Enforcement Official Activity Category must be provided.',
      'NIBRS-2019.2-121(Enforcement Official Assignment Category)':
        'When the Victim Category is L then Enforcement Official Assignment Category must be provided.',
      'NIBRS-2019.2-144':
        'When the Victim Injury Category is N, then no other injuries can be provided.',
      'NIBRS-2019.2-145':
        'When the Victim Injury Category is M, then Injury Categories (B,L,O,T) are invalid.',
      'NIBRS-2019.2-134':
        'When the Victim Aggravated Assault Homicide Factor is (10), then only one Aggravated Assault Circumstances code can be present.',
      'NIBRS-2019.2-136':
        'No more than 2 Victim Aggravated Assault Homicide Factor can be provided for Offense codes 09A or 13A.',
      'NIBRS-2019.2-137':
        'No more than 1 Victim Aggravated Assault Homicide Factor can be provided for Offense codes 09B.',
      'NIBRS-2019.2-138':
        'No more than 1 Victim Aggravated Assault Homicide Factor can be provided for Offense codes 09C.',
      'NIBRS-2019.2-183': 'If Victim is Society, you can only have one victim.',
      'NIBRS-2019.2-140':
        'Victim Justifiable Homicide Factor can only be submitted when the associated Offense code is 09C.',
      'NIBRS-2019.2-132':
        'The Victim associated to Victim Aggravated Assault Homicide Factor must be unique.',
      'NIBRS-2019.2-196':
        'The Victim Aggravated Assault Homicide Factor is required when the associated Offense is (09A,09B,09C,13A).',
      'NIBRS-2019.2-901':
        'The Victim Injury Type is required when the associated Offense is (100,11A,11B,11C,11D,120,13A,13B,210,64A,64B)',
      'NIBRS-2019.2-197':
        'When the Offense code is 09C, then Aggravated Assault Homicide Factor must be 20 or 21',
      'NIBRS-2019.2-142':
        'Additional Justifiable Homicide Factor is required when Aggravated Assault Homicide Factor is 20 or 21',
    },
  },
});

const getOffenseCode = (codeOffenseCodes, code) =>
  codeOffenseCodes.find((codeOffenseCode) => codeOffenseCode === code);

export const incVictimSchema = new SimpleSchema({
  aggravatedAssaultHomicide: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-196: Victim Aggravated Assault Homicide Factor
      if (['09A', '09B', '09C', '13A'].includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (!this.value) return 'NIBRS-2019.2-196';
      }

      if (!this.value) return;
      const parsedValues = JSON.parse(this.value);
      if (!parsedValues) return;

      const codeAggravatedAssault = store.store.getState().dictionary.codeAggAssaultType;
      const assaultCodes =
        parsedValues?.map((value) => getCategoryOfCode(codeAggravatedAssault, value)) || [];
      // NIBRS-2019.2-133: Victim Aggravated Assault Homicide Factor
      if (!['09A', '09B', '09C', '13A'].includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (parsedValues?.length) {
          return 'NIBRS-2019.2-133';
        }
      }

      // NIBRS-2019.2-134: Victim Aggravated Assault Homicide Factor
      if (assaultCodes?.includes('10')) {
        if (parsedValues?.length > 1) {
          return 'NIBRS-2019.2-134';
        }
      }

      // NIBRS-2019.2-136: Victim Aggravated Assault Homicide Factor
      if (['09A', '13A'].includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (parsedValues?.length > 2) {
          return 'NIBRS-2019.2-136';
        }
      }

      // NIBRS-2019.2-137: Victim Aggravated Assault Homicide Factor
      if (this.obj.offense.values.statuteDetails?.FBICode === '09B') {
        if (parsedValues?.length > 1) {
          return 'NIBRS-2019.2-137';
        }
      }

      // NIBRS-2019.2-138: Victim Aggravated Assault Homicide Factor
      if (this.obj.offense.values.statuteDetails?.FBICode === '09C') {
        if (parsedValues?.length > 1) {
          return 'NIBRS-2019.2-138';
        }
        if (parsedValues.length && parsedValues[0] !== '20' && parsedValues[0] !== '21') {
          return 'NIBRS-2019.2-197';
        }
      }

      // NIBRS-2019.2-132: Victim Aggravated Assault Homicide Factor
      let errorFlag = false;
      parsedValues.forEach((value) => {
        if (this.obj.incVictims?.length > 1) {
          for (let victim of this.obj.incVictims) {
            if (victim.incVictimDetails?.ptsVictimId === this.obj.incVictim?.ptsVictimId) {
              continue;
            }
            if (!victim.incVictimDetails?.values?.aggravatedAssaultHomicide) {
              break;
            }
            let parsed = JSON.parse(victim.incVictimDetails?.values?.aggravatedAssaultHomicide);
            if (!parsed) {
              break;
            }
            if (parsed.includes(value)) {
              errorFlag = true;
            }
          }
        }
      });
      if (errorFlag) {
        return 'NIBRS-2019.2-132';
      }
    },
  },
  injuryType: {
    type: String,
    label: 'Person Injury',
    optional: true,
    custom() {
      const injuryTypeStringArray = this.value ? this.value : '[]';
      const injuryType = JSON.parse(injuryTypeStringArray);
      const victimCategory = this.obj.incVictim.values.category;
      const FBICode = this.obj.offense.values.statuteDetails?.FBICode;
      const codeInjuryCategory = store.store.getState().dictionary.codeInjuryCategory;
      const codeVictimCategory = store.store.getState().dictionary.codeVictimCategory;

      const injuryTypeCategory =
        injuryType?.map((value) => getCategoryOfCode(codeInjuryCategory, value)) || [];

      const codeVictimCategoryType = getCategoryOfCode(codeVictimCategory, victimCategory);

      //NIBRS NIBRS-2019.2-901: Person Injury
      if (!injuryType || !injuryType.length) {
        const FBICodes = [
          '100',
          '11A',
          '11B',
          '11C',
          '11D',
          '120',
          '13A',
          '13B',
          '210',
          '64A',
          '64B',
        ];
        if (
          FBICodes.some((item) => FBICode === item) &&
          ['i', 'l'].includes(codeVictimCategoryType)
        ) {
          return 'NIBRS-2019.2-901';
        }
      }

      // NIBRS-2019.2-143
      if (injuryType.length && victimCategory && !['i', 'l'].includes(codeVictimCategoryType)) {
        return 'NIBRS-2019.2-143';
      }

      // NIBRS-2019.2-146
      if (FBICode === '13B') {
        if (!injuryTypeCategory.includes('m') && !injuryTypeCategory.includes('n'))
          return 'NIBRS-2019.2-146';
      }

      // NIBRS-2019.2-141
      if (
        injuryType.length &&
        !injuryTypeCategory.includes('n') &&
        !['100', '11A', '11B', '11C', '11D', '120', '13A', '13B', '210', '64A', '64B'].find(
          (element) => element === FBICode
        )
      ) {
        return 'NIBRS-2019.2-141';
      }

      //NIBRS-2019.2-144: Person Injury
      if (injuryType.length && injuryTypeCategory.includes('n') && injuryType.length > 1) {
        return 'NIBRS-2019.2-144';
      }

      //NIBRS-2019.2-145: Person Injury
      const invalidInjuryTypes = ['b', 'l', 'o', 't'];
      const shouldRaiseError =
        injuryType.length &&
        injuryTypeCategory.includes('m') &&
        invalidInjuryTypes.some((item) => injuryTypeCategory.includes(item));

      if (shouldRaiseError) return 'NIBRS-2019.2-145';
    },
  },
  category: {
    type: String,
    label: 'Victim Category',
    optional: true,
    custom() {
      if (!this.value) {
        return 'NIBRS-2019.2-115';
      }
      const codeVictimCategory = store.store.getState().dictionary.codeVictimCategory;
      const codeVictimCategoryType = getCategoryOfCode(
        codeVictimCategory,
        this.obj.incVictim.values.category
      );

      // NIBRS-2019.2-116
      if (
        getOffenseCode(crimeAgainstPersonCodes, this.obj.offense.values.statuteDetails?.FBICode)
      ) {
        if (!['i', 'l'].includes(codeVictimCategoryType)) {
          return 'NIBRS-2019.2-116';
        }
      }

      // NIBRS-2019.2-117
      if (
        getOffenseCode(crimeAgainstSocietyCodes, this.obj.offense.values.statuteDetails?.FBICode)
      ) {
        if (codeVictimCategoryType !== 's') {
          return 'NIBRS-2019.2-117';
        }
      }

      // NIBRS-2019.2-118
      if (
        getOffenseCode(crimeAgainstPropertyCodes, this.obj.offense.values.statuteDetails?.FBICode)
      ) {
        if (codeVictimCategoryType === 's') {
          return 'NIBRS-2019.2-118';
        }
      }

      // NIBRS-2019.2-119: Victim Type
      if (codeVictimCategoryType === 'l') {
        if (
          !['09A', '13A', '13B', '13C'].includes(this.obj.offense.values.statuteDetails?.FBICode)
        ) {
          return 'NIBRS-2019.2-119';
        }
      }
    },
  },
  victimType: {
    type: String,
    optional: true,
    custom() {
      const codeVictimCategory = store.store.getState().dictionary.codeVictimCategory;

      //NIBRS-2019.2-183: Victim Type
      //TODO: Improve the code logic
      if (this.obj.incVictims.length > 1) {
        let isSociety;

        for (let incVictim of this.obj.incVictims) {
          if (
            getCategoryOfCode(codeVictimCategory, incVictim.incVictimDetails.values.category) ===
            's'
          ) {
            isSociety = true;
            break;
          }
        }

        if (isSociety) {
          return 'NIBRS-2019.2-183';
        }
      }
    },
  },
  justifiableHomicide: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-140: Victim Justifiable Homicide Factor
      if (this.value) {
        if (this.obj.offense.values.statuteDetails?.FBICode !== '09C') {
          return 'NIBRS-2019.2-140';
        }
      }
    },
  },
  additionalJustifiableHomicide: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-142: Victim Additional Justifiable Homicide Factor
      if (
        this.obj.offense.values.statuteDetails?.JustifiableHomicide === true &&
        this.obj.incVictim.values?.aggravatedAssaultHomicide
      ) {
        const parsedValues = JSON.parse(this.obj.incVictim.values?.aggravatedAssaultHomicide);
        if (!parsedValues) return;

        const codeAggravatedAssault = store.store.getState().dictionary.codeAggAssaultType;
        const assaultCodes =
          parsedValues?.map((value) => getCategoryOfCode(codeAggravatedAssault, value)) || [];

        if (assaultCodes?.includes('20') || assaultCodes?.includes('21')) {
          if (!this.value) {
            return 'NIBRS-2019.2-142';
          }
        }
      }
    },
  },
  officerActivity: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-121: OfficerActivity
      const codeVictimCategory = store.store.getState().dictionary.codeVictimCategory;

      if (getCategoryOfCode(codeVictimCategory, this.obj.incVictim.values.category) === 'l') {
        if (!this.obj.incVictim.values.officerAssignment) {
          return 'NIBRS-2019.2-121(Enforcement Official Activity Category)';
        }
      }
    },
  },
  officerAssignment: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-121: OfficerActivity
      const codeVictimCategory = store.store.getState().dictionary.codeVictimCategory;
      if (getCategoryOfCode(codeVictimCategory, this.obj.incVictim.values.category) === 'l') {
        if (!this.obj.incVictim.values.officerAssignment) {
          return 'NIBRS-2019.2-121(Enforcement Official Assignment Category)';
        }
      }
    },
  },
});
