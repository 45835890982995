import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';

import { Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Hidden, Paper, TextField, useMediaQuery } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { gridStyle } from 'utils/formStyles';
import { getDataOfDictionaryOptions } from 'utils/functions';

import RMSDictionary from 'components/RMSAutoComplete/RMSDictonary';
import AgencyIcon from 'components/AgencyIcon';
import PersonSearchInfoCard from 'components/RMSPersonInfoCard/PersonSearchInfoCard';
import RMSSaveButton from 'components/RMSButtons/RMSSaveButton';
import RMSCloseButton from 'components/RMSButtons/RMSCloseButton';
import WarrantPrintsMenu from 'components/RMSVerticalMenu/WarrantPrintsMenu';
import ReportsDialog from 'components/RMSModals/ReportsDialog';
import RecordHistory from 'components/RecordHistory';

const PRIMARY_COLOR = '#1977D4';

const useStyles = makeStyles((theme) => ({
  item2: gridStyle('100%', 800),
  gridBg: {
    width: '100%',
    margin: '0px 0px 4px 0px',
    display: 'block',
    //padding: '16px 5px',
  },
  dialogBox: {
    maxWidth: '850px',
    minHeight: '150px',
  },
  title: {
    fontWeight: 'bolder',
  },
  fieldsetWrapper: {
    padding: '11px 5px',
    width: '100%',
    height: '125px',
  },
  fieldsetWrapperBorder: {
    border: '2px solid',
    borderColor: theme.palette.colors.borderColor,
    borderRadius: '10px',
  },
  fieldsetWrapperCreate: {
    border: '1px solid #1e8dfa',
    borderRadius: '10px',
    padding: '25px 4px 15px',
    height: '172px',
    width: '100%',
  },
  fieldsetWrapperCurrentView: {
    border: '1px solid #1e8dfa',
    borderRadius: '10px',
    padding: '15px 13px 5px',
    height: '172px',
    width: '97%',
  },
  fieldsetWrapperFilter: {
    border: '1px solid #1e8dfa',
    borderRadius: '10px',
    padding: '15px 13px 5px',
    height: '172px',
    width: '100%',
  },
  dialogFieldsetWrapperFilter: {
    border: '1px solid #1e8dfa',
    borderRadius: '10px',
    padding: '15px 13px 5px',
    height: '80%',
    width: '100%',
  },
  customPadding: {
    paddingLeft: '4px',
    [theme.breakpoints.only('lg')]: {
      paddingLeft: '0px',
    },
  },
  chipsItem: {
    background: '#1e8dfa',
    height: '34px',
    color: 'white',
    width: 'auto',
    fontWeight: 'bold',
    fontSize: '15px',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      width: '100%',
    },
  },
  chipsItem2: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.colors.chipBg,
    borderRadius: '5px',
    paddingRight: '8px',
    color: theme.palette.colors.label,
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      width: '100%',
    },
  },
  incidentIdClass: { color: theme.palette.colors.chipBg },
  fieldsetTitle: {
    position: 'absolute',
    marginTop: '-23px',
    padding: '2px 10px',
    marginLeft: '10px',
  },
  fieldsetTitleBackground: {
    color: 'white',
    background: theme.palette.colors.chipBg,
    borderRadius: '10px',
  },
  fieldsetTitleCreate: {
    position: 'absolute',
    marginTop: '-32px',
    color: 'white',
    background: '#1e8dfa',
    borderRadius: '10px',
    padding: '2px 8px',
  },
  fieldsetTitleCurrentView: {
    position: 'absolute',
    marginTop: '-25px',
    color: 'white',
    background: '#1e8dfa',
    borderRadius: '10px',
    padding: '2px 10px',
  },
  fieldsetTitleFilter: {
    position: 'absolute',
    marginTop: '-25px',
    color: 'white',
    background: '#1e8dfa',
    borderRadius: '10px',
    padding: '2px 10px',
  },
  synopsis: {
    backgroundColor: 'inherit',
    color: theme.palette.colorFont,
    padding: '10px',
    '&:focus': {
      border: '2px solid #1976d2',
    },
  },
  closeButton: {
    color: theme.button.color,
  },
  svg_icons: {
    color: PRIMARY_COLOR,
    marginTop: '50px',
  },
  svg_icons_rotated: {
    transform: 'rotate(180deg)',
    color: PRIMARY_COLOR,
    marginTop: '50px',
  },
  fabStyle: {
    backgroundColor: theme.palette.colors.chipBg,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  editIncidentFieldsetWrapper: {
    border: '1px solid #1e8dfa',
    borderRadius: '10px',
    padding: '15px 15px 15px',
    marginTop: '25px',
  },
  editIncidentFieldsetTitle: {
    position: 'absolute',
    marginTop: '-28px',
    color: 'white',
    background: '#1e8dfa',
    borderRadius: '10px',
    padding: '2px 8px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    // marginBottom: theme.spacing(2),
    // '& button': {
    //   marginLeft: theme.spacing(1),
    // },
  },
  gridBg2: {
    width: '100%',
    border: '2px solid',
    borderRadius: '10px',
    borderColor: theme.palette.colors.borderColor,
    height: '173px',
    display: 'block',
    paddingLeft: '5px',
    paddingTop: '2px',
  },
}));
function WarrantDetailsBar(props) {
  const classes = useStyles();
  const { warrant, onChange } = props;

  const [personInfo, setPersonInfo] = useState({});
  const [tab, setTab] = useState('edit');
  const [open, setOpen] = useState(false);
  const [reportsData, setReportsData] = useState({});
  const [recordHistoryData, setRecordHistoryData] = useState({
    shouldVisible: true,
    isDisabled: true,
    createUpdateInfo: {},
  });
  const [showRecordHistory, setShowRecordHistory] = useState(false);

  const wsClient = useSelector((state) => state.websocket);
  const dictionary = useSelector((state) => state.dictionary);
  const { Agencies, codeWarrantClasses, codeBondTypes, codeWarrantDispositions } = dictionary;
  const onDialogClose = () => setOpen(false);
  useEffect(() => {
    getPerson();

    if (warrant) {
      setRecordHistoryData({
        shouldVisible: true,
        isDisabled: !warrant.Created,
        createUpdateInfo: {
          created: warrant.Created,
          createdBy: warrant.CreatedBy,
          updated: warrant.Updated,
          updatedBy: warrant.UpdatedBy,
        },
      });
    }
  }, [wsClient, warrant.ptsPersonID, warrant]);

  const handleCloseRecordHistoryDialog = () => setShowRecordHistory(false);

  const getPerson = async () => {
    if (!wsClient?.websocket) {
      return;
    }

    const service = wsClient?.websocket.service('record-person-details');
    service.timeout = 200000;

    const result = await service.get(warrant.ptsPersonID);

    setPersonInfo(result);
  };

  const generateReport = (type, id) => {
    setOpen(true);
    setReportsData({ id, data: { ptsWarrantID: warrant.ptsWarrantID } });
  };
  const renderIssueAgency = () => {
    const value = Agencies.find((item) => item.AgencyID === warrant.IssueAgencyID) || null;
    return (
      <RMSDictionary
        compact
        options="Agencies"
        value={value}
        className={classes.item2}
        onChange={(e, value) => onChange('IssueAgencyID', value?.AgencyID)}
        label="Issue Agency"
        error={!warrant.IssueAgencyID && ' This Field Is Required'}
      />
    );
  };

  const renderIssueDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Issue Date"
          value={warrant.IssuedDate}
          onChange={(date) => onChange('IssuedDate', date)}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderDispositionCode = () => {
    const handleChange = (e, value) => {
      /** Set IsActive field based on the warrant category */
      const warrantCategory = getDataOfDictionaryOptions(
        'codeWarrantDispositions',
        'IntCode',
        'Category',
        value?.IntCode
      )?.toLowerCase();
      const warrantActive = warrantCategory === 'active' ? true : false;

      onChange('IsActive', warrantActive);

      onChange('DispositionIntCode', value?.IntCode);
      onChange('DispositionCode', value?.Code);
    };
    const value =
      codeWarrantDispositions.find((item) => item.IntCode === warrant.DispositionIntCode) || null;
    return (
      <RMSDictionary
        compact
        options="codeWarrantDispositions"
        className={classes.item2}
        onChange={handleChange}
        label="Disposition"
        value={value}
        error={!warrant.DispositionIntCode && ' This Field Is Required'}
      />
    );
  };

  const renderDispositionDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          isableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Disposition Date"
          value={warrant.DispositionDate}
          onChange={(date) => onChange('DispositionDate', date)}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderBondAmount = () => {
    return (
      <TextField
        label="Bond Amount"
        variant="outlined"
        onChange={(e) => {
          onChange('BondAmount', e.target.value);
        }}
        value={warrant.BondAmount}
        className={classes.item2}
        size="small"
        type="number"
      />
    );
  };

  const renderBondType = () => {
    const value = codeBondTypes.find((item) => item.Code === warrant.BondType) || null;
    return (
      <RMSDictionary
        compact
        options="codeBondTypes"
        className={classes.item2}
        onChange={(e, value) => onChange('BondType', value?.Code)}
        value={value}
        label="Bond Type"
      />
    );
  };

  const renderBondDate = () => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          isableOpenOnEnter
          autoComplete="off"
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label="Bond Date"
          value={warrant.BondDate}
          onChange={(date) => onChange('BondDate', date)}
          autoOk
          size="small"
          className={classes.item2}
        />
      </MuiPickersUtilsProvider>
    );
  };

  const renderWarrantClass = () => {
    const value = codeWarrantClasses.find((item) => item.Code === warrant.WarrantClass) || null;
    return (
      <RMSDictionary
        compact
        options="codeWarrantClasses"
        className={classes.item2}
        onChange={(e, value) => onChange('WarrantClass', value?.Code)}
        value={value}
        label="Warrant Class"
      />
    );
  };
  return (
    <Paper className={classes.gridBg}>
      <Hidden mdDown>
        <Grid container>
          <Grid item container xs={11} spacing={2} alignItems="center">
            <Grid item xs={2} container justify="center">
              <Grid item xs={12}>
                <Typography
                  variant={'button'}
                  display="block"
                  gutterBottom
                  style={{
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}>
                  {warrant.WarrantID}
                </Typography>
              </Grid>
              <Grid item container spacing={1} xs={12} justify="center">
                <Hidden lgDown>
                  <Grid
                    item
                    xl={4}
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <AgencyIcon AgencyID={warrant.IssueAgencyID} color={'#2871CC'} size={3} />
                  </Grid>
                </Hidden>

                <Grid item lg={12} xl={8} container>
                  <Grid item xs={12}>
                    {renderIssueAgency()}
                  </Grid>
                  <Grid item xs={12}>
                    {/* {renderWarrantID()} */}
                    {renderIssueDate()}
                  </Grid>
                  <Grid item xs={12}>
                    {renderWarrantClass()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={7} className={classes.gridBg2} container>
              <PersonSearchInfoCard
                data={personInfo}
                personSearch={true}
                setTab={setTab}
                dispositionIntCode={warrant.DispositionIntCode}
                origin="warrantsInfoCard"
                viewOnly={props.viewOnly}
              />
            </Grid>
            <Grid container item xs={3}>
              <Grid container xs={12}>
                <Grid item xs={6} lg={12} xl={6}>
                  {renderBondType()}
                </Grid>
                <Grid item xs={3} lg={12} xl={6} className={classes.customPadding}>
                  {renderDispositionCode()}
                </Grid>
                <Grid item xs={3} lg={6}>
                  {renderBondAmount()}
                </Grid>
                <Grid item xs={3} lg={6} className="pl-1">
                  {renderDispositionDate()}
                </Grid>
                <Grid item xs={3} lg={6}>
                  {renderBondDate()}
                </Grid>
                {/* <Grid item xs={6} style={{ paddingLeft: '5px' }}>
              {renderWarrantClass()}
            </Grid> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={1}>
            <Grid container alignItems="flex-end" spacing={1} direction={'column'} className="pr-4">
              {recordHistoryData?.shouldVisible && (
                <Grid item xs={3} className="pl-3">
                  <RecordHistory
                    showRecordHistory={showRecordHistory}
                    setShowRecordHistory={setShowRecordHistory}
                    disabled={recordHistoryData?.isDisabled}
                    handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
                    createUpdateInfo={recordHistoryData?.createUpdateInfo}></RecordHistory>
                </Grid>
              )}
              <Grid item xs={3} className="">
                <WarrantPrintsMenu
                  tooltipText="print warrant"
                  size="20px"
                  generateReport={(type, id) => generateReport(type, id)}
                  type="edit"
                />
              </Grid>
              <Grid item xs={3} className="pl-3">
                <RMSSaveButton disabled={props.viewOnly} onClick={props.onSave} />
              </Grid>
              <Grid item xs={3} className="pl-3">
                <RMSCloseButton onClick={props.onClose} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden lgUp>
        <Grid container>
          <Grid item container xs={12} spacing={2} alignItems="center">
            <Grid item lg={2} container justify="center" className="pb-2">
              <Grid item xs={6}>
                <Typography
                  variant={'button'}
                  display="block"
                  gutterBottom
                  style={{
                    textAlign: 'left',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}>
                  {warrant.WarrantID}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: 'flex', justifyContent: 'right', paddingBottom: '10px' }}>
                {recordHistoryData?.shouldVisible && (
                  <Grid item>
                    <RecordHistory
                      showRecordHistory={showRecordHistory}
                      setShowRecordHistory={setShowRecordHistory}
                      disabled={recordHistoryData?.isDisabled}
                      handleCloseRecordHistoryDialog={handleCloseRecordHistoryDialog}
                      createUpdateInfo={recordHistoryData?.createUpdateInfo}></RecordHistory>
                  </Grid>
                )}
                <Grid item className="">
                  <WarrantPrintsMenu
                    tooltipText="print warrant"
                    size="20px"
                    generateReport={(type, id) => generateReport(type, id)}
                    type="edit"
                  />
                </Grid>
                <Grid item className="mr-1">
                  <RMSSaveButton onClick={props.onSave} />
                </Grid>
                <Grid item>
                  <RMSCloseButton onClick={props.onClose} />
                </Grid>
              </Grid>
              <Grid container xs={12} justify="center">
                <Grid item lg={8} xs={6} container>
                  <Grid item xs={12}>
                    {renderIssueAgency()}
                  </Grid>
                  <Grid item xs={12}>
                    {/* {renderWarrantID()} */}
                    {renderIssueDate()}
                  </Grid>
                  <Grid item xs={12}>
                    {renderWarrantClass()}
                  </Grid>
                </Grid>

                <Grid container item xs={6} spacing={0.5} className="pl-2">
                  <Grid container xs={12}>
                    <Grid item xs={6}>
                      {renderBondType()}
                    </Grid>
                    <Grid item xs={6} className="pl-2">
                      {renderDispositionCode()}
                    </Grid>
                    <Grid item xs={6}>
                      {renderBondAmount()}
                    </Grid>
                    <Grid item xs={6} className="pl-2">
                      {renderDispositionDate()}
                    </Grid>
                    <Grid item xs={6}>
                      {renderBondDate()}
                    </Grid>
                    {/* <Grid item xs={6} style={{ paddingLeft: '5px' }}>
              {renderWarrantClass()}
            </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Hidden lgDown></Hidden> */}
          </Grid>
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12} className={classes.gridBg2} container>
            <PersonSearchInfoCard
              data={personInfo}
              personSearch={true}
              setTab={setTab}
              dispositionIntCode={warrant.DispositionIntCode}
              origin="warrantsInfoCard"
            />
          </Grid>
        </Hidden>
      </Hidden>
      {open && <ReportsDialog open={open} onClose={onDialogClose} report={reportsData} />}
    </Paper>
  );
}

export default WarrantDetailsBar;
